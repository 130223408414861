import * as React from 'react'
import { Link } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import ShopLayout from '../layouts/Shop'

const IndexPage = () => (
  <ShopLayout>
    <Page>
      <Container>
        <h2>Wie komme ich zu meinen Produkten?</h2>
        <h4>Möglichkeit 1: Abholung im Hofladen</h4>
        <p>wir richten die Bestellung spätestens bis nächsten Tag 9:00 her</p>
        <h4>Möglichkeit 2: Abholung am Markt</h4>
        <p>wir richten die Bestellung her und Sie holen sie auf einem unseren Märkte ab.</p>
        {/*
        <h4>Möglichkeit 3: Lieferservice</h4>

        <p>Voll bepackt mit feinen Produkten, machen wir uns auf den Weg:</p>
        <ul>
          <li>Im Gemeindegebiet von Neumarkt & ca. 10 km darüber hinaus (Termin: flexibel nach Vereinbarung)</li>
          <li>Im Raum Salzburg (Termin: Donnerstag Vormittag)</li>
        </ul>
        <p>Unkostenbeitrag: 5€ pro Lieferung, ab 70€ Bestellsumme gratis Lieferung</p>
        */}

        <h2>Wie funktioniert die Bezahlung?</h2>
        <p>Bei Abholung im Hofladen: Bezahlung am SB-Terminal mit Karte oder in bar.</p>
        <p>Bei Abholung am Markt: Bitte einfach bar bezahlen.</p>
        {/*
        <p>
          Bei Zustellung: Wenn wir das Packerl zusammengerichtet haben, melden wir uns nochmals und informieren über den Betrag und den
          ungefähren Zeitpunkt der Lieferung. Sobald wir an Ihrer Haustüre stehen, übergeben wir Ihnen das Paket und Sie uns das Geld.
          Vorzugsweise genau in einem unauffälligen Umschlag.
        </p>
        */}
        {/*
        <p>
          NEU: Es gibt jetzt auch die Möglichkeit per Sofortüberweisung online zu bezahlen. Wählen Sie dazu die entsprechende Zahlungsart
          und sie erhalten einen Link per E-Mail zur Bezahlung.
        </p>
        */}
      </Container>
    </Page>
  </ShopLayout>
)

export default IndexPage
